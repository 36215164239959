<template>
  <div>
    <v-data-table
      :loading="requestLoading"
      :headers="resultTableHeader"
      :items="table.items"
      :options.sync="table.options"
      item-key="id"
      class="elevation-1"
      :footer-props="{
        itemsPerPageOptions: [10]
      }"
    >
      <template v-slot:[`item.entityType`]="{ item }">
        <v-icon
          v-if="item.entityType == 'INDIVIDUAL'"
          color="blue lighten-3"
        >
          mdi-account
        </v-icon>
        <v-icon
          v-else
          color="blue lighten-3"
        >
          mdi-bank
        </v-icon>
      </template>
      <template v-slot:[`item.fullName`]="{ item }">
        <div>
          <span class="font-weight-bold">{{ item.fullName }}</span>
        </div>
        <small class="caption grey--text">
          {{ $t('ewe_screen.entities_table.column.entityId.label') }}: {{ item.entityID }}
        </small>
      </template>
      <template v-slot:[`item.similarity`]="{ item }">
        <v-tooltip
          v-if="item.similarityLevel"
          right
          :color="item.similarityLevel === 'EXACT'
            ? 'error' : (
              item.similarityLevel === 'STRONG'
                ? 'warning' : (
                  item.similarityLevel === 'WEAK'
                    ? 'success' : ''
                )
            )"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              v-if="item.similarityLevel === 'EXACT'"
              color="error"
            >
              mdi-signal-cellular-3
            </v-icon>
            <v-icon
              v-bind="attrs"
              v-on="on"
              v-else-if="item.similarityLevel === 'STRONG'"
              color="warning"
            >
              mdi-signal-cellular-2
            </v-icon>
            <v-icon
              v-bind="attrs"
              v-on="on"
              v-else-if="item.similarityLevel === 'WEAK'"
              color="success"
            >
              mdi-signal-cellular-1
            </v-icon>
            <v-icon v-else>
              signal-cellular-outline
            </v-icon>
          </template>
          <span v-if="item.similarityLevel === 'EXACT'">{{ $t('ewe_screen.entities_table.column.similarity.exact') }}</span>
          <span v-else-if="item.similarityLevel === 'STRONG'">{{ $t('ewe_screen.entities_table.column.similarity.strong') }}</span>
          <span v-else-if="item.similarityLevel === 'WEAK'">{{ $t('ewe_screen.entities_table.column.similarity.weak') }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.picture`]="{ item }">
        <v-menu
          :nudge-width="200"
          bottom
          right
          offset-x
          open-on-hover
          :disabled="!item.picture"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-img
              v-bind="attrs"
              v-on="on"
              slot="activator"
              max-width="45"
              class="my-2"
              :src="item.picture || defaultPortraitIcon"
              aspect-ratio="1"
              :contain="item.picture ? false : true"
              :style="item.picture ? 'cursor: zoom-in' : ''"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey"
                  />
                </v-row>
              </template>
            </v-img>
          </template>
          <v-card>
            <v-img
              contain
              max-width="245"
              :src="item.picture || defaultPortraitIcon"
            />
          </v-card>
        </v-menu>
      </template>
      <template v-slot:[`item.category`]="{ item }">
        <ScreeningCategoryChips :category="item.category" />
      </template>
      <template v-slot:[`item.dateOfBirth`]="{ item }">
        {{ `${(item.dateOfBirth && item.dateOfBirth.length >= 4 ? item.dateOfBirth : null) || 'NA'} / ${(item.dateOfDeath && item.dateOfDeath.length >= 4 ? item.dateOfDeath : null) || 'NA' }` }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          @click="popup_scan_detail( {scanID: scanDetail.review_token , entityID: item.entityID}, scanDetail.details.hitDetails.findIndex(i => i.entityID === item.entityID) )"
          data-cy="btn_viewScanDetail"
        >
          mdi-magnify
        </v-icon>
      </template>
      <template v-slot:[`item.country`]="{ item }">
        <span
          v-for="(country, index) in item.countryISO2.slice(0, 3)"
          :key="index"
          class="mr-1"
        >
          <flag
            :iso="country.iso2"
            :title="($i18n.locale.toLowerCase() === 'zh-hant' ? country.displayNameZhHant : country.displayName)"
            :squared="false"
            style="font-size:20px"
          />
        </span>
        <span
          v-if="item.countryISO2.length > 3"
          class="grey--text text-caption"
        >
          <br>
          + {{ item.countryISO2.length - 3 }} countr(ies)
        </span>
        <span
          v-if="item.countryISO2.length === 0"
        >
          N/A
        </span>
      </template>
    </v-data-table>
    <v-dialog
      v-model="entityDialog"
      max-width="1250px"
      persistent
    >
      <v-card>
        <ScreeningDetail
          :entity-detail="entityDetail"
          @close="entityDialog = false"
        />
      </v-card>
      <v-btn
        color="white"
        id="prev_button"
        x-small
        fab
        @click="prevRecord()"
        v-if="entityIndex > 0"
        width="50px"
        height="50px"
      >
        <v-icon
          large
          color="blue"
        >
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-btn
        color="white"
        id="next_button"
        x-small
        fab
        @click="nextRecord()"
        v-if="entityIndex < table.items.length - 1"
        width="50px"
        height="50px"
      >
        <v-icon
          large
          color="blue"
        >
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </v-dialog>
  </div>
</template>

<script>
import ScreeningDetail from '@/components/popups/PopupScreeningDetail'
import ScreeningCategoryChips from '@/components/common/ScreeningCategoryChips'
import DefaultPortraitIcon from '@/assets/default_portrait_picture.svg'

export default {
  components: {
    ScreeningDetail,
    ScreeningCategoryChips
  },
  props: {
    scanDetail: {
      type: Object,
      required: true
    }
  },
  watch: {
    scanDetail: {
      handler () {
        this.updateTableItems()
      },
      deep: true
    }
  },
  computed: {
    defaultPortraitIcon () {
      return DefaultPortraitIcon
    },
    resultTableHeader () {
      return [
        { text: this.$t('ewe_screen.entities_table.header.type'), value: 'entityType', width: '5%', sortable: false, class: 'grey lighten-3 text-uppercase' },
        { text: this.$t('ewe_screen.entities_table.header.name'), value: 'fullName', width: '20%', sortable: false, class: 'grey lighten-3 text-uppercase' },
        { text: this.$t('ewe_screen.entities_table.header.similarity'), value: 'similarity', width: '10%', sortable: false, class: 'grey lighten-3 text-uppercase' },
        { text: this.$t('ewe_screen.entities_table.header.portrait'), value: 'picture', width: '10%', sortable: false, class: 'grey lighten-3 text-uppercase' },
        { text: this.$t('ewe_screen.entities_table.header.category'), value: 'category', width: '20%', sortable: false, class: 'grey lighten-3 text-uppercase' },
        { text: `${this.$t('ewe_screen.entities_table.header.dob')}/${this.$t('ewe_screen.entities_table.header.doi')}`, value: 'dateOfBirth', width: '10%', sortable: false, class: 'grey lighten-3 text-uppercase' },
        { text: this.$t('ewe_screen.entities_table.header.country'), value: 'country', align: 'center', width: '10%', sortable: false, class: 'grey lighten-3 text-uppercase' },
        { text: '', value: 'action', width: '5%', sortable: false, class: 'grey lighten-3 text-uppercase' }
      ]
    }
  },
  data () {
    return {
      entityDetail: null,
      entityDialog: false,
      requestLoading: false,
      entityIndex: 0,
      table: {
        options: {
          page: 1,
          sortBy: 'name',
          sortDesc: false
        },
        items: []
      }
    }
  },
  methods: {
    updateTableItems () {
      this.table.options.page = 1
      this.table.items = JSON.parse(JSON.stringify(this.scanDetail.details.hitDetails)).sort((a, b) => {
        if (a.similarity > b.similarity) return -1
        if (a.similarity < b.similarity) return 1
        if (a.picture && !b.picture) return -1
        if (!a.picture && b.picture) return 1
        return 0
      })
    },
    prevRecord () {
      this.entityIndex -= 1
      this.popup_scan_detail({ scanID: this.scanDetail.review_token, entityID: this.scanDetail.details.hitDetails[this.entityIndex].entityID })
    },
    nextRecord () {
      this.entityIndex += 1
      this.popup_scan_detail({ scanID: this.scanDetail.review_token, entityID: this.scanDetail.details.hitDetails[this.entityIndex].entityID })
    },
    popup_scan_detail (entityDetail, index) {
      if (index !== undefined) {
        this.entityIndex = index
      }
      this.entityDialog = true
      this.entityDetail = entityDetail
    }
  },
  mounted () {
    this.updateTableItems()
  }
}
</script>

<style scoped>
#resultTable {
  min-width: 100%;
}

#next_button {
  position:absolute;
  right:10px;
  top:50%;
  transform:translateY(-50%);
}

#prev_button {
  position:absolute;
  left:10px;
  top:50%;
  transform:translateY(-50%);
}
</style>
