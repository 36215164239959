<template>
  <v-select
    clearable
    v-model="selection"
    multiple
    :dense="dense"
    :label="label"
    :items="items"
    :error="error"
    :error-messages="errorMessages"
    :data-cy="dataCy"
  >
    <template v-slot:prepend-item>
      <v-list-item
        ripple
        @mousedown.prevent
        @click="toggle"
        dense
        :data-cy="`${dataCy}_select_all`"
      >
        <v-list-item-action>
          <v-icon :color="selection.length > 0 ? 'primary' : ''">
            {{ isSelectedAll ? 'mdi-checkbox-marked' : ( isSelectedSome ? 'mdi-minus-box' : 'mdi-checkbox-blank-outline' ) }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('common.multipleSelector.selectAll') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
    </template>
    <template v-slot:selection="{ item, index }">
      <div
        v-if="index === 0"
        :data-cy="`${dataCy}_selection`"
      >
        {{ isSelectedAll ? $t('common.multipleSelector.selection.all', { labelPlural }) : ( item instanceof Object && item.value ? item.text : item ) }}
      </div>
      <span
        v-if="index === 1 && !isSelectedAll"
        class="grey--text text-caption"
      >
        &nbsp;({{ $t('common.multipleSelector.selection.nItemSelected', { n: selection.length - 1 }) }})
      </span>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Array],
      default: null
    },
    dense: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: String,
      default: ''
    },
    dataCy: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    labelPlural: {
      type: String,
      default: ''
    }
  },
  computed: {
    isSelectedAll () {
      return this.selection.length === this.items.length
    },
    isSelectedSome () {
      return this.selection.length > 0 && !this.isSelectedAll
    }
  },
  watch: {
    selection (_new) {
      this.$emit('input', _new)
    },
    value (_new) {
      this.selection = _new
    }
  },
  data: () => ({
    selection: []
  }),
  methods: {
    toggle () {
      this.$nextTick(() => {
        if (this.isSelectedAll) {
          this.selection = []
        } else {
          this.selection = this.items.slice()
        }
      })
    }
  }
}
</script>

<style>

</style>
