<template>
  <v-card
    class="mx-auto"
    width="100%"
  >
    <v-card-title>
      <v-app-bar
        flat
        class="white pa-0"
        elevate-on-scroll
        dense
      >
        <v-toolbar-title>
          {{ $t('ewe_screen.title') }}
        </v-toolbar-title>
        <v-spacer />
      </v-app-bar>
    </v-card-title>
    <v-card-text>
      <v-container
        class="pa-0"
      >
        <v-row>
          <v-card
            class="mx-auto pa-5"
            width="97%"
          >
            <!-- client type button -->
            <v-row class="mx-auto">
              <v-col cols="6">
                <v-form @submit.prevent="onSubmit">
                  <v-container
                    class="pl-6"
                  >
                    <v-row>
                      <v-tabs @change="(tab) => form.type = (tab == 0 ? 'individual' : 'corporate' )">
                        <v-tab data-cy="tab_Individual">
                          <v-icon
                            small
                            left
                          >
                            mdi-account-group
                          </v-icon>
                          {{ $t('ewe_screen.form.type.options.individual') }}
                        </v-tab>
                        <v-tab data-cy="tab_Corporate">
                          <v-icon
                            small
                            left
                          >
                            mdi-bank
                          </v-icon>
                          {{ $t('ewe_screen.form.type.options.corporate') }}
                        </v-tab>
                      </v-tabs>
                    </v-row>
                    <!-- Name -->
                    <v-row
                      class="mt-2"
                    >
                      <v-col
                        class="pa-0"
                        cols="8"
                      >
                        <v-text-field
                          autocomplete="off"
                          height="30"
                          v-model="form.name"
                          :error="$v.form.name.$error"
                          :error-messages="$v.form.name.$error ? $t('validate.required', { field: $t('ewe_screen.form.name.label') }) : ''"
                          :label="$t('ewe_screen.form.name.label')"
                          data-cy="name_input"
                        >
                          <!-- <template v-slot:label>
                            <span class="input_label">
                              {{ $t('ewe_screen.form.name.label') }}
                            </span>
                          </template> -->
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <!-- Dob -->
                    <v-row v-if="form.type == 'individual'">
                      <v-col
                        :class="{
                          'pa-0': true,
                          'pr-5': form.type == 'individual'
                        }"
                        :cols="form.type == 'individual'? 4 : 8"
                      >
                        <InputDate
                          dense
                          solo
                          :label="$t('ewe_screen.form.dob.label')"
                          v-model="form.dob"
                          ref="dob"
                          prepend-inner-icon="mdi-calendar-range"
                          data-cy="dob_input"
                        />
                      </v-col>
                      <v-col
                        class="pa-0"
                        cols="4"
                      >
                        <MultipleSelector
                          ref="gender"
                          v-model="form.gender"
                          dense
                          :items="[
                            { value: 'Male', text: $t('ewe_screen.form.gender.options.male') },
                            { value: 'Female', text: $t('ewe_screen.form.gender.options.female') },
                            // { value: 'Others', text: $t('ewe_screen.form.gender.options.others') },
                          ]"
                          :label="$t('ewe_screen.form.gender.label')"
                          :label-plural="$t('ewe_screen.form.gender.labelPlural')"
                          :error="$v.form.gender.$error"
                          :error-messages="$v.form.gender.$error ? $t('validate.required', { field: $t('ewe_screen.form.gender.label') }) : ''"
                          data-cy="gender_input"
                        />
                      </v-col>
                    </v-row>
                    <!-- Country -->
                    <v-row>
                      <v-col
                        class="pa-0 d-flex"
                        cols="8"
                      >
                        <country-selector
                          ref="country"
                          v-model="form.country"
                          :label="$t('ewe_screen.form.country.label')"
                          :label-plural="$t('ewe_screen.form.country.labelPlural')"
                          multiple
                          dense
                          :show-flag="true"
                          data-cy="country_input"
                        />
                      </v-col>
                    </v-row>
                    <!-- Categories -->
                    <v-row>
                      <v-col
                        class="pa-0"
                        cols="8"
                      >
                        <MultipleSelector
                          ref="categories"
                          v-model="form.categories"
                          dense
                          :items="categories"
                          :label="$t('ewe_screen.form.categories.label')"
                          :label-plural="$t('ewe_screen.form.categories.labelPlural')"
                          :error="$v.form.categories.$error"
                          :error-messages="$v.form.categories.$error ? $t('validate.required', { field: $t('ewe_screen.form.categories.label') }) : ''"
                          data-cy="categories_input"
                        />
                      </v-col>
                    </v-row>
                    <!-- All screening list -->
                    <v-row v-if="organization_name === 'TESS Asia Limited'">
                      <v-col
                        class="pa-0 d-flex"
                        cols="8"
                      >
                        <v-slider
                          class="pt-5"
                          dense
                          label="Similarity"
                          v-model="form.minConfidenceScore"
                          thumb-label="always"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-btn
                        style="height: 36px; min-width: 36px; padding: 0px;"
                        color="error"
                        class="mr-3"
                        @click="resetForm(true)"
                        data-cy="reset_button"
                      >
                        <v-icon small>
                          mdi-reload
                        </v-icon>
                      </v-btn>
                      <v-btn
                        color="primary"
                        type="submit"
                        data-cy="submit_button"
                      >
                        <v-icon left>
                          mdi-magnify
                        </v-icon>
                        <!-- <span class="text-capitalize"> -->
                        {{ $t('ewe_screen.form.btn.search.label') }}
                        <!-- </span> -->
                      </v-btn>
                    </v-row>
                  </v-container>
                </v-form>
              </v-col>
              <v-divider
                vertical
                color="white"
                style="margin-top: 30px; margin-bottom: 30px"
              />
              <v-col
                cols="auto"
                style="width: 49%"
              >
                <loading
                  :active.sync="loading"
                  :is-full-page="false"
                />
                <v-container
                  class="pl-6"
                  v-if="scanDetail"
                >
                  <v-row
                    class="pb-6"
                  >
                    <v-toolbar-title
                      class="font-weight-bold"
                    >
                      {{ $t('ewe_screen.result.title') }}
                    </v-toolbar-title>
                  </v-row>
                  <v-row
                    align="center"
                    class="pb-2"
                  >
                    <span class="font-weight-bold pr-6">
                      {{ $t('ewe_screen.result.table.scan_no.label') }}
                    </span>
                    <span class="font-weight-bold primary--text">{{ scanDetail.scan_id }}</span>
                  </v-row>
                  <v-row
                    align="center"
                    class="pb-2"
                  >
                    <v-icon
                      small
                      color="black"
                      class="pr-2"
                    >
                      mdi-clock-outline
                    </v-icon>
                    <span class="font-weight-bold text-justify text-center">
                      {{ scanAt }}
                    </span>
                  </v-row>
                  <v-row
                    align="center"
                    class="pb-2"
                  >
                    <v-icon
                      small
                      color="black"
                      class="pr-2"
                    >
                      mdi-account
                    </v-icon>
                    <span class="font-weight-bold">{{ username }}</span>
                  </v-row>
                  <!-- Search Criteria -->
                  <v-row
                    class="pb-10"
                    v-if="searchCriteria"
                  >
                    <fieldset
                      id="searchCriteria"
                    >
                      <legend
                        class="ml-3 font-weight-bold px-2"
                        style="font-size: 16px;"
                      >
                        {{ $t('ewe_screen.result.table.fieldset.title') }}
                      </legend>
                      <table>
                        <tr>
                          <td
                            class="pr-2"
                          >
                            <v-icon
                              small
                              color="black"
                            >
                              mdi-cube-scan
                            </v-icon>
                          </td>
                          <td
                            class="font-weight-bold primary--text"
                          >
                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ (searchCriteria.entityType.toLowerCase() == 'individual' ? $t('ewe_screen.form.type.options.individual') : $t('ewe_screen.form.type.options.corporate')) }}
                                </span>
                              </template>
                              <span>
                                {{ $t('ewe_screen.result.table.fieldset.entityType.label') }}
                              </span>
                            </v-tooltip>
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="pr-2"
                          >
                            <v-icon
                              small
                              color="black"
                            >
                              mdi-format-size
                            </v-icon>
                          </td>
                          <td
                            class="font-weight-bold primary--text"
                          >
                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ searchCriteria.name || 'N/A' }}
                                </span>
                              </template>
                              <span>
                                {{ $t('ewe_screen.result.table.fieldset.name.label') }}
                              </span>
                            </v-tooltip>
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="pr-2"
                          >
                            <v-icon
                              small
                              color="black"
                            >
                              mdi-calendar-range
                            </v-icon>
                          </td>
                          <td
                            class="font-weight-bold primary--text"
                          >
                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ searchCriteria.dob }}
                                </span>
                              </template>
                              <span>
                                {{ $t('ewe_screen.result.table.fieldset.dob.label') }}
                              </span>
                            </v-tooltip>
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="font-weight-black pr-2"
                          >
                            <v-icon
                              small
                              color="black"
                            >
                              mdi-map-marker
                            </v-icon>
                          </td>
                          <td
                            class="font-weight-bold primary--text"
                          >
                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ searchCriteria.countries.length ? searchCriteria.countries.join(',') : $t('ewe_screen.result.table.fieldset.country.all') }}
                                </span>
                              </template>
                              <span>
                                {{ $t('ewe_screen.result.table.fieldset.country.label') }}
                              </span>
                            </v-tooltip>
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="font-weight-black pr-2"
                          >
                            <v-icon
                              small
                              color="black"
                            >
                              mdi-gender-male-female
                            </v-icon>
                          </td>
                          <td
                            class="font-weight-bold primary--text"
                          >
                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ searchCriteria.gender || $t('ewe_screen.result.table.fieldset.gender.all') }}
                                </span>
                              </template>
                              <span>
                                {{ $t('ewe_screen.result.table.fieldset.gender.label') }}
                              </span>
                            </v-tooltip>
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="font-weight-black pr-2"
                          >
                            <v-icon
                              small
                              color="black"
                            >
                              mdi-view-grid-outline
                            </v-icon>
                          </td>
                          <td
                            class="font-weight-bold primary--text"
                          >
                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ searchCriteria.categories.length ? searchCriteria.categories.join(', ') : $t('ewe_screen.result.table.fieldset.category.all') }}
                                </span>
                              </template>
                              <span>
                                {{ $t('ewe_screen.result.table.fieldset.category.label') }}
                              </span>
                            </v-tooltip>
                          </td>
                        </tr>
                        <!-- <tr>
                          <td
                            class="font-weight-black pr-2"
                          >
                            <v-icon
                              small
                              color="black"
                            >
                              mdi-format-list-bulleted-square
                            </v-icon>
                          </td>
                          <td
                            class="font-weight-bold primary--text"
                          >
                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ searchCriteria.screeninglist || 'N/A' }}
                                </span>
                              </template>
                              <span>
                                {{ $t('ewe_screen.result.table.fieldset.screening_list.label') }}
                              </span>
                            </v-tooltip>
                          </td>
                        </tr> -->
                      </table>
                    </fieldset>
                  </v-row>
                  <v-row
                    justify="end"
                  >
                    <v-btn
                      color="primary"
                      class="mr-3"
                      @click="onExport"
                    >
                      <v-icon left>
                        mdi-content-save
                      </v-icon>
                      <!-- <span class="text-capitalize"> -->
                      {{ $t('ewe_screen.result.btn.save.label') }}
                      <!-- </span> -->
                    </v-btn>
                    <v-btn
                      v-if="!onboardingComplete"
                      color="success"
                      @click="onOnboarding"
                      :loading="onboardingLoading"
                    >
                      <v-icon>
                        mdi-handshake
                      </v-icon>
                      <!-- <span class="text-capitalize"> -->
                      {{ $t('ewe_screen.result.btn.onboard.label') }}
                      <!-- </span> -->
                    </v-btn>
                    <v-btn
                      v-if="onboardingComplete && onboardingClientId"
                      :to="{
                        name: this.formOnboarding.type === 'individual' ? 'NaturalPersonEdit' : 'CompanyEdit',
                        params: {
                          [this.formOnboarding.type === 'individual' ? 'np_id' : 'customer_id']: this.onboardingClientId
                        }
                      }"
                      target="_blank"
                      color="success"
                      class="mr-3"
                    >
                      <v-icon left>
                        mdi-file-find
                      </v-icon>
                      {{ $t('ewe_screen.result.btn.viewClient.label') }}
                    </v-btn>
                    <v-btn
                      v-if="onboardingComplete && onboardingAlertCaseId"
                      :to="{
                        name: 'AlertCaseDetails',
                        params: {
                          case_id: onboardingAlertCaseId
                        }
                      }"
                      target="_blank"
                      color="success"
                    >
                      <v-icon left>
                        mdi-alert-circle-outline
                      </v-icon>
                      {{ $t('ewe_screen.result.btn.viewAlertCase.label') }}
                    </v-btn>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
      </v-container>
    </v-card-text>
    <v-container
      class="ma-0 pa-0"
      id="resultTable"
      style="max-width: 100%;"
    >
      <ScreeningResultsTable
        v-if="scanDetail"
        :scan-detail="scanDetail"
      />
    </v-container>
  </v-card>
</template>

<script>
import CountrySelector from '@/components/forms/CountrySelector'
import InputDate from '@/components/forms/InputDate'
import { mapGetters, mapActions, mapState } from 'vuex'
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import MultipleSelector from '@/components/forms/MultipleSelector'
import ScreeningResultsTable from '@/components/common/ScreeningResultsTable'

Vue.use(Vuelidate)

function initialData () {
  return {
    type: 'individual',
    name: '',
    gender: '',
    dob: '',
    country: [],
    categories: [],
    screeninglist: [],
    minConfidenceScore: 30
  }
}

export default {
  name: 'SingleScan',
  components: {
    InputDate,
    // ScreeningDetail,
    CountrySelector,
    MultipleSelector,
    // ScreeningCategoryChips,
    ScreeningResultsTable
  },
  data () {
    return {
      // entityDialog: false,
      requestLoading: false,
      // entityIndex: 0,
      form: initialData(),
      formOnboarding: null,
      loading: false,
      onboardingLoading: false,
      onboardingClientId: null,
      onboardingAlertCaseId: null,
      onboardingComplete: false,
      // table: {
      //   options: {
      //     page: 1,
      //     sortBy: 'name',
      //     sortDesc: false
      //   },
      //   items: []
      // },
      scanDetail: null,
      scanAt: null,
      // entityDetail: null,
      searchCriteria: null,
      result: null,
      cachedSearchEntities: []
    }
  },
  validations () {
    return {
      form: {
        name: {
          required
        },
        gender: {
        },
        country: {
        },
        categories: {
        },
        screeninglist: {
        }
      }
    }
  },
  computed: {
    ...mapGetters('auth', [
      'username'
    ]),
    ...mapState('auth', [
      'organization_name',
      // 'role',
      'access_menus'
    ]),
    ...mapGetters('setting', {
      countries: 'country_table'
    }),
    ...mapGetters('scan', {
      screeninglists: 'screeninglist',
      categories: 'categories'
    })
  },
  methods: {
    ...mapActions('scan', [
      'nameScreening',
      'nameScreeningEWE',
      'getScreeningResults',
      'getScreeningResultsEntity',
      'fetchScreeningList',
      'fetchCategories'
    ]),
    resetForm (all) {
      Object.assign(this.form, {
        ...initialData(),
        type: this.form.type
      })
      if (all) {
        this.result = null
        // this.table.items = []
        this.searchCriteria = null
        this.scanDetail = null
        this.entityDetail = null
      }
      this.$v.form.$reset()
      this.requestLoading = false
      this.loading = false
    },
    onSubmit () {
      this.$v.form.$touch()
      if (!this.$v.$invalid && (!this.$refs.dob || (this.$refs.dob && this.$refs.dob.correctDate))) {
        this.loading = true
        this.scanDetail = null
        const year = this.moment(this.form.dob, 'YYYY-MM-DD').format('YYYY')
        this.nameScreeningEWE({
          name: this.form.name,
          entityType: this.form.type === 'individual' ? 'Individual' : 'Corporate',
          countries: this.$refs.country.isSelectedAll ? [] : this.form.country,
          yearOfBirth: year === 'Invalid date' ? null : year,
          gender:
            this.$refs.gender && this.$refs.gender.isSelectedAll
              ? null
              : (
                this.form.gender.length
                  ? this.form.gender[0]
                  : null
              ),
          // deceased: this.form.deceased,
          // lastScreenAt: this.form.lastScreenAt,
          categories: this.$refs.categories.isSelectedAll ? [] : this.form.categories,
          minConfidenceScore: this.form.minConfidenceScore
        })
          .then(res => {
            if (res.status === 200) {
              this.scanDetail = res.data
              this.scanAt = this.moment().locale(this.$i18n.locale === 'zh-Hant' ? 'zh-tw' : 'en').format('DD-MMM-YYYY HH:mm:ss')
              this.searchCriteria = {
                dob: this.form.dob,
                ...JSON.parse(res.config.data)
              }
              if (this.scanDetail.hit === 'Y') {
                this.onboardingLoading = false
                this.onboardingComplete = false
                this.formOnboarding = {
                  ...this.form
                }
                this.resetForm(false)
              } else {
                this.$notify({
                  type: 'success',
                  title: 'Name Screening Result',
                  text: 'Name Screening Not Hit'
                })
                this.onboardingLoading = false
                this.onboardingComplete = false
                this.formOnboarding = {
                  ...this.form
                }
                this.resetForm(false)
              }
            } else {
              this.$notify({
                type: 'error',
                title: 'Error',
                text: 'Name Screening Failed to Execute'
              })
              this.resetForm(false)
            }
          })
          .catch(unusedErr => {
            this.$notify({
              type: 'error',
              title: 'Error',
              text: 'Name Screening Failed to Execute'
            })
            this.requestLoading = false
            this.loading = false
          })
      }
    },
    // isoCode (country) {
    //   var result = this.countryItems.filter(obj => {
    //     return obj.text.includes(country)
    //   })
    //   return result[0].iso2
    // },
    // getDisplayName (country) {
    //   return ((this.$i18n.locale.toLowerCase() === 'zh-hant' ? country.displayNameZhHant : country.display_name) || country.country)
    // },
    // popup_scan_detail (entityDetail, index) {
    //   if (index !== undefined) {
    //     this.entityIndex = index
    //   }
    //   this.entityDialog = true
    //   this.entityDetail = entityDetail
    // },
    onExport () {
      const routeData = this.$router.resolve({ name: 'ReportScreening', query: { } })
      this.windowRef = window.open(routeData.href, '', 'width=920,height=800,left=200,top=200')
      window.childWindowReady = async () => {
        this.windowRef.setReportData({
          ...this.scanDetail,
          entities: await Promise.all(this.scanDetail.details.hitDetails.map(hit => {
            return this.getScreeningResultsEntity({
              scanID: this.scanDetail.review_token,
              entityID: hit.entityID
            }).then(res => {
              return res.data
            })
          }))
        }, this.organization_name, this.username)
      }
    },
    async onOnboarding () {
      this.onboardingLoading = true
      // 1. create client (individual / corporate)
      this.$store
        .dispatch(
          this.form.type === 'individual'
            ? 'natural_person/save'
            : 'company/save',
          this.form.type === 'individual'
            ? {
              status: 1, // default active
              pob: this.formOnboarding.country.length > 0 ? this.formOnboarding.country[0] : '',
              dob: this.formOnboarding.dob || '',
              names: [{
                name_type: 1,
                lang: 1,
                sur: '',
                other: this.formOnboarding.name
              }] || [],
              gender:
                this.formOnboarding.gender.length === 1
                  ? this.formOnboarding.gender[0]
                  : (
                    this.formOnboarding.gender.length === 2
                      ? 'Others'
                      : ''
                  )
            }
            : {
              status: 1, // default active
              incorp_place: this.formOnboarding.country.length > 0 ? this.formOnboarding.country[0] : '',
              incorp_date: this.formOnboarding.dob || '',
              company_type: 1,
              names: [{
                name_type: 3,
                lang: 1,
                other: this.formOnboarding.name,
                sur: ''
              }] || []
            }
        )
        .then(res => {
          if (res.status === 200) {
            // 2. create alert case & alert case entities
            this.onboardingClientId = res.data.id
            this.onboardingAlertCaseId = null
            this.$store
              .dispatch('alert_case/createEntities', {
                review_token: this.scanDetail.review_token,
                [this.form.type === 'individual' ? 'person' : 'company']: res.data.id
              })
              .then(res => {
                console.log(res)
                if (res.data.length > 0) {
                  this.onboardingAlertCaseId = res.data[0].alert_case
                }
                this.onboardingComplete = true
              })
              .catch(unusedErr => {
                this.onboardingComplete = true
                this.$notify({
                  type: 'error',
                  title: 'Error',
                  text: 'Failed to create alert case entities'
                })
              })
              .finally(() => {
                this.onboardingLoading = false
              })
          }
        })
        .catch(unusedErr => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'Failed to create client'
          })
        })
    }
  },
  mounted () {
    this.loading = true
    this.fetchCategories()
    this.fetchScreeningList()
    this.loading = false
  }
}
</script>

<style type="text/css">
.screeningTypeBtn {
  width: 150px;
  height: 40px !important;
}

/* .input_label {
  font-weight: bold;
  color: black;
} */

.v-text-field {
  margin: 0 !important;
  padding-top: 10px !important;
}

.v-input__control{
    min-height: 30px!important;
    max-height: 60px;
}

.v-text-field__details {
  margin: 0 !important;
}

#searchCriteria{
  border-radius: 10px;
  border-width: 2px;
  border-color: black;
  padding-left: 20px;
  padding-bottom: 5px;
  width: 100%;
}

</style>
