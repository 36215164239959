var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.requestLoading,"headers":_vm.resultTableHeader,"items":_vm.table.items,"options":_vm.table.options,"item-key":"id","footer-props":{
      itemsPerPageOptions: [10]
    }},on:{"update:options":function($event){return _vm.$set(_vm.table, "options", $event)}},scopedSlots:_vm._u([{key:"item.entityType",fn:function(ref){
    var item = ref.item;
return [(item.entityType == 'INDIVIDUAL')?_c('v-icon',{attrs:{"color":"blue lighten-3"}},[_vm._v(" mdi-account ")]):_c('v-icon',{attrs:{"color":"blue lighten-3"}},[_vm._v(" mdi-bank ")])]}},{key:"item.fullName",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.fullName))])]),_c('small',{staticClass:"caption grey--text"},[_vm._v(" "+_vm._s(_vm.$t('ewe_screen.entities_table.column.entityId.label'))+": "+_vm._s(item.entityID)+" ")])]}},{key:"item.similarity",fn:function(ref){
    var item = ref.item;
return [(item.similarityLevel)?_c('v-tooltip',{attrs:{"right":"","color":item.similarityLevel === 'EXACT'
          ? 'error' : (
            item.similarityLevel === 'STRONG'
              ? 'warning' : (
                item.similarityLevel === 'WEAK'
                  ? 'success' : ''
              )
          )},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(item.similarityLevel === 'EXACT')?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-signal-cellular-3 ")]):(item.similarityLevel === 'STRONG')?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v(" mdi-signal-cellular-2 ")]):(item.similarityLevel === 'WEAK')?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-signal-cellular-1 ")]):_c('v-icon',[_vm._v(" signal-cellular-outline ")])]}}],null,true)},[(item.similarityLevel === 'EXACT')?_c('span',[_vm._v(_vm._s(_vm.$t('ewe_screen.entities_table.column.similarity.exact')))]):(item.similarityLevel === 'STRONG')?_c('span',[_vm._v(_vm._s(_vm.$t('ewe_screen.entities_table.column.similarity.strong')))]):(item.similarityLevel === 'WEAK')?_c('span',[_vm._v(_vm._s(_vm.$t('ewe_screen.entities_table.column.similarity.weak')))]):_vm._e()]):_vm._e()]}},{key:"item.picture",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{attrs:{"nudge-width":200,"bottom":"","right":"","offset-x":"","open-on-hover":"","disabled":!item.picture},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"my-2",style:(item.picture ? 'cursor: zoom-in' : ''),attrs:{"slot":"activator","max-width":"45","src":item.picture || _vm.defaultPortraitIcon,"aspect-ratio":"1","contain":item.picture ? false : true},slot:"activator",scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('v-card',[_c('v-img',{attrs:{"contain":"","max-width":"245","src":item.picture || _vm.defaultPortraitIcon}})],1)],1)]}},{key:"item.category",fn:function(ref){
          var item = ref.item;
return [_c('ScreeningCategoryChips',{attrs:{"category":item.category}})]}},{key:"item.dateOfBirth",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s((((item.dateOfBirth && item.dateOfBirth.length >= 4 ? item.dateOfBirth : null) || 'NA') + " / " + ((item.dateOfDeath && item.dateOfDeath.length >= 4 ? item.dateOfDeath : null) || 'NA')))+" ")]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{attrs:{"data-cy":"btn_viewScanDetail"},on:{"click":function($event){_vm.popup_scan_detail( {scanID: _vm.scanDetail.review_token , entityID: item.entityID}, _vm.scanDetail.details.hitDetails.findIndex(function (i) { return i.entityID === item.entityID; }) )}}},[_vm._v(" mdi-magnify ")])]}},{key:"item.country",fn:function(ref){
          var item = ref.item;
return [_vm._l((item.countryISO2.slice(0, 3)),function(country,index){return _c('span',{key:index,staticClass:"mr-1"},[_c('flag',{staticStyle:{"font-size":"20px"},attrs:{"iso":country.iso2,"title":(_vm.$i18n.locale.toLowerCase() === 'zh-hant' ? country.displayNameZhHant : country.displayName),"squared":false}})],1)}),(item.countryISO2.length > 3)?_c('span',{staticClass:"grey--text text-caption"},[_c('br'),_vm._v(" + "+_vm._s(item.countryISO2.length - 3)+" countr(ies) ")]):_vm._e(),(item.countryISO2.length === 0)?_c('span',[_vm._v(" N/A ")]):_vm._e()]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"1250px","persistent":""},model:{value:(_vm.entityDialog),callback:function ($$v) {_vm.entityDialog=$$v},expression:"entityDialog"}},[_c('v-card',[_c('ScreeningDetail',{attrs:{"entity-detail":_vm.entityDetail},on:{"close":function($event){_vm.entityDialog = false}}})],1),(_vm.entityIndex > 0)?_c('v-btn',{attrs:{"color":"white","id":"prev_button","x-small":"","fab":"","width":"50px","height":"50px"},on:{"click":function($event){return _vm.prevRecord()}}},[_c('v-icon',{attrs:{"large":"","color":"blue"}},[_vm._v(" mdi-chevron-left ")])],1):_vm._e(),(_vm.entityIndex < _vm.table.items.length - 1)?_c('v-btn',{attrs:{"color":"white","id":"next_button","x-small":"","fab":"","width":"50px","height":"50px"},on:{"click":function($event){return _vm.nextRecord()}}},[_c('v-icon',{attrs:{"large":"","color":"blue"}},[_vm._v(" mdi-chevron-right ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }