<template>
  <v-container
    style="max-width: 100%"
    class="pa-0 ma-0 white"
  >
    <loading
      :active.sync="requestLoading"
      :is-full-page="false"
    />
    <v-app-bar
      width="100%"
      height="50px"
      class="px-4 white"
      scroll-target="#scrolling-screening-detail"
      elevate-on-scroll
      dense
      style="z-index: 2;"
    >
      <v-col
        cols="1"
      >
        <span
          class="grey--text"
        >
          {{ $t('dialogs.screeningDetail.entityID') }}
        </span>
      </v-col>
      <v-col
        cols="2"
      >
        <span
          class="primary--text font-weight-bold"
        >
          {{ data.entityID }}
        </span>
      </v-col>
      <v-col
        cols="1"
      >
        <span
          class="grey--text"
        >
          {{ $t('dialogs.screeningDetail.entityType.label') }}
        </span>
      </v-col>
      <v-col
        cols="2"
        justify="center"
      >
        <v-icon
          v-if="String(data.entityType).toUpperCase() == 'INDIVIDUAL'"
          color="blue lighten-3"
        >
          mdi-account
        </v-icon>
        <v-icon
          v-else
          color="blue lighten-3"
        >
          mdi-bank
        </v-icon>
        <span
          class="primary--text text-capitalize font-weight-bold"
        >
          {{ String(data.entityType).toUpperCase() == 'INDIVIDUAL' ? $t('dialogs.screeningDetail.entityType.individual') : $t('dialogs.screeningDetail.entityType.corporate') }}
        </span>
      </v-col>
      <v-col
        class="pa-0"
        cols="6"
        align="right"
      >
        <!-- <ScreeningReport
          v-model="openReport"
          :data="data"
        /> -->
        <v-btn
          color="primary"
          text
          @click.prevent="downloadReport"
        >
          <v-icon left>
            mdi-download
          </v-icon>
          {{ $t('dialogs.screeningDetail.buttons.export') }}
        </v-btn>
        <v-btn
          text
          color="error"
          @click="$emit('close')"
          data-cy="btn_closeScreeningDetail"
        >
          {{ $t('dialogs.screeningDetail.buttons.close') }}
        </v-btn>
      </v-col>
    </v-app-bar>

    <EntityContent
      class="overflow-y-auto"
      style="max-height: calc(90vh - 80px);transform: scale(1);"
      :entity-detail="entityDetail"
      :data.sync="data"
      :request-loading.sync="requestLoading"
    />
  </v-container>
</template>

<script>
// import { mapGetters, mapState, mapActions } from 'vuex'
import EntityContent from '@/components/popups/PopupScreeningDetail/EntityContent'

export default {
  name: 'ScreeningDetail',
  components: {
    EntityContent
  },
  props: {
    entityDetail: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      // name: ['Wai', 'Ch8n', 'Meng', 'Adam'],
      // show: true,
      requestLoading: true,
      data: {
        entityID: '',
        entityType: '',
        enteredDate: 0,
        updatedDate: '',
        category: [],
        country: null,
        dateOfBirth: null,
        dateOfDeath: null,
        title: null,
        picture: null,
        names: [],
        addresses: [],
        email: null,
        businessPhone: '',
        homePhone: '',
        mobilePhone: '',
        fax: '',
        associations: [],
        notes: [],
        articles: [],
        sanctions: [],
        politicalPosition: [],
        firstName: null,
        middlename: null,
        lastName: null,
        businessName: null,
        website: null
      },
      openReport: false
    }
  },

  watch: {
  },

  methods: {
    downloadReport () {
      const routeData = this.$router.resolve({ name: 'ReportScreeningEntity', query: { } })
      this.windowRef = window.open(routeData.href, '', 'width=920,height=800,left=200,top=200')
      // this.windowRef.document.body.appendChild(this.$el)
      // copyStyles(window.document, this.windowRef.document)
      // this.windowRef.addEventListener('beforeunload', this.closePortal)
      window.childWindowReady = () => {
        this.windowRef.setReportData({ ...this.data }, this.organization_name, this.username)
      }
    }
  },

  computed: {
  },

  mounted () {
  }
}
</script>

<style type="text/css">

.flexcol .v-btn__content {
  flex-direction: column;
}

.v-dialog {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.v-dialog::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

.spanText {
  display: inline-block;
  width: 200px;
  padding-left: 20px;
}

.roundBorder {
  border-radius: 15px !important;
}

a:hover {
  font-weight: bold !important;
}

/* Sanction table */
.sanctionTable {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  font-size: 0.875rem;
}

.sanctionTable tr td {
  border-right: 5px solid #F44336;
  border-left: 5px solid #F44336;
  height: 30px
}

.sanctionTable tr th {
  background: #F44336;
  border: 2px solid #F44336;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  text-align: start;
  height: 30px;
  vertical-align:bottom;
}

.sanctionTable tr:nth-child(odd){
  background-color: #FCE4EC;
}

.sanctionTable tr:last-child td{
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom: 5px solid #F44336;
}

/* Political Table */
.politicalTable {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  font-size: 0.875rem;
}

.politicalTable tr td {
  border-right: 5px solid #2196F3;
  border-left: 5px solid #2196F3;
  height: 30px
}

.politicalTable tr th {
  background: #2196F3;
  border: 2px solid #2196F3;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  text-align: start;
  height: 30px;
  vertical-align:bottom;
}

.politicalTable tr:nth-child(odd){
  background-color: #E3F2FD;
}

.politicalTable tr:last-child td{
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom: 5px solid #2196F3;
}

.politicalTable tr:not([style*="display:none;"]):last-child td {
  border-bottom: 5px solid #2196F3;
}

.__shuffle-item {
  max-width: 25%;
  width: 100%;
  padding: 24px 0 0 24px;
}

.__shuffle-list {
  overflow: visible !important; margin: 0 0 0 -24px;
}
</style>
